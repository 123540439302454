button.btn-stock {
	&:not(.selected) {
		background-color: var(--bs-secondary);

		&:hover {
			background-color: transparent;
		}
	}

	&.selected {
		background-color: var(--bs-primary);
		color: var(--bs-white);
	}
}