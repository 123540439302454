button.btn-stock:not(.selected) {
  background-color: var(--bs-secondary);
}
button.btn-stock:not(.selected):hover {
  background-color: transparent;
}
button.btn-stock.selected {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

i, .duration-name, .no-option {
  color: #666;
}

.print {
  cursor: pointer;
}

#dashboard th, #dashboard td {
  border: 1px solid var(--bs-secondary);
}
#dashboard .booking {
  margin: 0 10px;
  background-color: var(--bs-primary);
  cursor: pointer;
  transition-duration: 0.2s;
}
#dashboard .booking:hover {
  background-color: #33b1f9;
}