i, .duration-name, .no-option {color: #666}

.print {
	cursor: pointer;
}

#dashboard {
	th, td {border: 1px solid var(--bs-secondary)}

	.booking {
		margin: 0 10px;
		background-color: var(--bs-primary);
		cursor: pointer;
		transition-duration: .2s;

		&:hover {background-color: #33b1f9}
	}
}